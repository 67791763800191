/* You can add global styles to this file, and also import other style files */


html {
  font-size: 16px;
}

body{
  color: #333333 ;
  font-family: 'Lato', sans-serif;
  font-size:16px;
  margin:0;
  background-color: #FFF;
  min-width: 320px;
}
body a, body a img { text-decoration: none; color: #f84848; cursor: pointer;  }

hr { border-top: #bbb 1px solid; border-bottom: 0px #fff solid; }

.btn-primary {
  color: #fff !important;
  background-color: #F44748  !important;
border-color: #f84848  !important;
text-decoration: none !important;
}

.btn-primary:hover {
  color: #fff !important;
  background-color: rgb(194, 27, 27)  !important;
border-color:  rgb(194, 27, 27)  !important;
text-decoration: none !important;
}

primary:focus {
  color: #fff;
  background-color: #f84848 !important;
  border-color: #f84848 !important;
  box-shadow: 0 0 0 0.2rem rgb(194 27 27 / 50%) !important;
}


.btn-especial {
  color: #fff !important;
  background-color: #222222  !important;
border-color: #222222  !important;
text-decoration: none !important;
}
.btn-lg { text-transform: none !important;
    font-size: 1.3rem !important;
    border-radius: .3rem;}

.btn-secondary {
  color: #fff !important;
  background-color: #aaa  !important;
border-color: #aaa  !important;
text-decoration: none !important;
}

.btn-info {
  color: #fff !important;
  background-color: #6c4bf7  !important;
  border-color: #6c4bf7  !important;
}

.btn-warning {
  color: #fff !important;
  background-color: #999  !important;
  border-color: #999  !important;
}

.btn-primary:hover,
.btn-primary:active,
.btn-primary:focus {
  color: #fff;
  background-color: #AD0C14;
border-color: #AD0C14;
text-decoration: none;
}

.btn-especial:hover,
.btn-especial:active,
.btn-especial:focus {
  color: #fff;
  background-color: #444444;
border-color: #444444;
text-decoration: none;
}



.btn-info:hover,
.btn-info:active,
.btn-info:focus {
  color: #fff;
  background-color: #3A1879;
  border-color: #3A1879;
}

.btn-warning:hover,
.btn-warning:active,
.btn-warning:focus {
  color: #fff;
  background-color: #C0510C;
  border-color: #C0510C;
}



/* Clases personalizadas*/
.header{
  background-color: #303030;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}

.titulo{
  font-size: 20px;
}

.texto-medio{
  font-size: 18px;
}

.texto-chico{
  font-size: 14px;
}

.nombre_usuario{
  color: #999999;
  font-size: 20px;
}
.no-hidden{
  position: fixed;
  bottom: 10px;
  right: 10px;
  visibility: visible;
}


.titulo-oferta{
  font-family: 'Alegreya Sans', sans-serif;
  font-size: 1.4em;
  color: #f44748;
  /* font-weight: bold; */
}
.descripcion{
  width: 350px;
}

.footer-oferta{
  margin: 0.2rem !important;
}

.button-megusta{
  background-color: transparent;
  /* color: #F44748; */
  border-color: transparent;
  cursor: pointer;
  font-size: 16px;

  margin: 0;
  display: inline-flex;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  position: relative;
}

.button-megusta:hover{
  color: #F44748;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

}

.button-megusta:active {
  color: #F44748;

  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  transform: translateY(2px);
    /* Moving button 4px to y-axis */
}

.button-megusta-selected{
  color: #F44748;
  background-color: transparent;
  /* color: #F44748; */
  border-color: transparent;
  cursor: pointer;
  font-size: 16px;

  margin: 0;
  display: inline-flex;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  vertical-align: bottom;
  text-align: center;
  overflow: hidden;
  position: relative;
}
.button-megusta-selected:hover{
  color: #1a1919;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12);

}


.button-megusta-selected:active {
  box-shadow: 7px 6px 28px 1px rgba(0, 0, 0, 0.24);
  transform: translateY(2px);
    /* Moving button 4px to y-axis */
}




/* Personalizacion de Prime Ng*/


.p-component {
  font-family: 'Lato', sans-serif !important;
}

.p-button{
  padding: 0.2rem 0.5rem !important;
  font-size: 0.8rem !important;
}
.p-button.p-button-text {
  color: #F44748 !important;
}


.p-inputtext:enabled:hover {
  border-color: #ccff66 !important;
}
.p-inputtext:enabled:focus {
  box-shadow: 0 0 0 0.2rem #ccff66 !important;
  border-color: #ccff66 !important;

}

.p-inputswitch {
  width: 1.8rem !important;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider:before {
  transform: translateX(0.7rem) !important;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider {
  background: #F44748 !important;
}

.p-inputswitch .p-inputswitch-slider{
  width: 1.8rem !important;
  height: 1rem !important;
  background: #303030 !important;
}

.p-inputswitch .p-inputswitch-slider:before {
  width: 0.8rem !important;
  height: 0.8rem !important;
  margin-top: -0.38rem !important;
  left: 0.1rem !important;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: 0 0 0 0.2rem #9ceba4 !important;
}
.p-inputswitch {
  width: 3rem;
  height: 1em !important;
}

.p-card {
  background: #f3f3f3 !important;
  color: #333333 !important;
}
.p-card .p-card-content {
  padding: 1rem 2rem 0.5rem !important;
}

.p-card .p-card-content-filtro {
  padding: 1rem !important;
}
.p-card .p-card-body {
  padding: 0 !important;
}

.p-selectbutton .p-button.p-highlight {
  background: #303030 !important;
  border-color: #303030 !important;
  color: #DDDDDD !important;
}
